import React, { FC, useCallback } from 'react'
import { AdProps, ComponentType, TeaserProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { RenderListItemProps } from '@sport1/news-styleguide/Types'
import { ItemCardProps } from '@/components/TeaserCard'
import { TeaserSizeTypes } from '@/types/teaser'
import HorizontalSlider from '@/components/HorizontalSlider'

const HorizontalCardContent: FC<ItemCardProps> = ({
    title,
    teaser,
    backgroundColor,
    getTeaserComponent,
    horizontalScrollbarEnabled,
    arrowMargin,
    spaceX,
}) => {
    const renderItem = useCallback(
        ({ index }: RenderListItemProps<TeaserProps | AdProps>) => {
            return getTeaserComponent({
                index,
                key: `${title}_${index}_all`,
                type: TeaserSizeTypes.MID,
                isCardTeaser: true,
                noExpand: true,
                verticalExpand: true,
                headlineNumberOfLines: 2,
                includeAdsForSizes: { isDesktop: true, isTablet: true, isMobile: true },
            })
        },
        [getTeaserComponent, title]
    )

    const keyExtractor = useCallback(
        (item: TeaserProps | AdProps, index: number) =>
            item.type !== ComponentType.AD
                ? `${item.title}_${item.publishedAt}_${index}`
                : `${item.adIndex}_${index}`,
        []
    )

    return (
        <NonFlexingContainer>
            <HorizontalSlider<TeaserProps | AdProps>
                items={teaser}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                backgroundColor={backgroundColor}
                spaceX={spaceX || 'none'}
                horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                arrowMargin={arrowMargin}
                itemWidth={290}
            />
        </NonFlexingContainer>
    )
}

export default HorizontalCardContent
